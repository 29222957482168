import React, { Fragment } from "react"
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid"
import Layout from "../components/layout"
import SEO from "../components/seo"

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    priceMonthly: "€999",
    description: "Quis suspendisse ut fermentum neque vivamus non tellus.",
    mostPopular: false,
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    priceMonthly: "€2599",
    description: "Quis eleifend a tincidunt pellentesque. A tempor in sed.",
    mostPopular: true,
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    priceMonthly: "€4999",
    description:
      "Orci volutpat ut sed sed neque, dui eget. Quis tristique non.",
    mostPopular: false,
  },
]
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Number of pages",
        tiers: { Basic: "1", Essential: "3", Premium: "6" },
      },
      {
        name: "Technical SEO",
        tiers: { Basic: true, Essential: true, Premium: true },
      },

      {
        name: "Lead form",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "Form filled per month",
        tiers: { Basic: "100", Essential: "500", Premium: "Unlimited" },
      },
      {
        name: "100% PageSpeed Insights",
        tiers: { Essential: true, Premium: true },
      },

      {
        name: "Blog option",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Product list page",
        tiers: { Premium: true },
      },
    ],
  },
  {
    name: "Technologies",
    features: [
      {
        name: "Develp by GatsbyJs",
        tiers: { Basic: true, Essential: true, Premium: true },
      },

      {
        name: "Deploy on Vercel",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "Google analytics",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "Headless CMS",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Access to source code",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "GraphQL API",
        tiers: { Essential: true, Premium: true },
      },

      {
        name: "Advanced analytics",
        tiers: { Premium: true },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "1h brainstorming session",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "Revision rounds ",
        tiers: { Basic: "1", Essential: "2", Premium: "3" },
      },
      {
        name: "Photo/video sourcing help",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Priority support",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Time estimate",
        tiers: { Basic: "1 week", Essential: "2 weeks", Premium: "4 weeks" },
      },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

// this is test
export default function HostingPage() {
  return (
    <Layout showNavbar={true}>
      <SEO title="Pricing" />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Simple pricing, No b**lshit
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          We offer transparent fixed-price packages for our custom website
          development with Gatsby JS. Static sites with a strong emphasis on
          technical SEO optimization, our websites are designed to give you a
          competitive advantage and drive organic traffic. We do high-end custom
          development, including things others might consider exclusive, so if
          you have any questions, reach out to us.
        </p>

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {tiers.map(tier => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "rounded-xl bg-white/5 ring-1 ring-inset ring-white/10"
                  : "",
                "p-8"
              )}
            >
              <h3 id={tier.id} className="font-semibold leading-6 text-white">
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold text-white">
                  {tier.priceMonthly}
                </span>
              </p>
              <a
                href="https://calendly.com/kalvispetersons/introductory-call"
                target="_blank"
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-indigo-500 text-white hover:bg-indigo-400 focus-visible:outline-indigo-500"
                    : "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white",
                  "mt-8 block rounded-md py-2 px-3 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                Let’s have a call
              </a>
              <ul role="list" className="mt-10 space-y-4 leading-6 text-white">
                {sections.map(section => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map(feature =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon
                              className="h-6 w-5 flex-none text-indigo-400"
                              aria-hidden="true"
                            />
                            <span>
                              {feature.name}{" "}
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <span className="leading-6 text-gray-400">
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            {tiers.some(tier => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (tiers.findIndex(tier => tier.mostPopular) + 1) * 25
                    }%`,
                  }}
                >
                  <div className="w-full rounded-t-xl border-x border-t border-white/10 bg-white/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map(tier => (
                    <th
                      key={tier.id}
                      scope="col"
                      className="px-6 pt-6 xl:px-8 xl:pt-8"
                    >
                      <div className="font-semibold leading-7 text-white">
                        {tier.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {tiers.map(tier => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline gap-x-1 text-white">
                        <span className="font-semibold leading-6">from</span>
                        <span className="text-4xl font-bold">
                          {tier.priceMonthly}
                        </span>
                      </div>
                      <a
                        href="https://calendly.com/kalvispetersons/introductory-call"
                        target="_blank"
                        className={classNames(
                          tier.mostPopular
                            ? "bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-600"
                            : "bg-white/10 hover:bg-white/20 focus-visible:outline-white",
                          "mt-8 block rounded-md py-2 px-3 text-center font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        )}
                      >
                        Let’s have a call
                      </a>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? "pt-8" : "pt-16",
                          "pb-4 font-semibold leading-6 text-white"
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-white/10" />
                      </th>
                    </tr>
                    {section.features.map(feature => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 font-normal leading-6 text-white"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-white/5" />
                        </th>
                        {tiers.map(tier => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div className="text-center leading-6 text-gray-300">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className="mx-auto h-5 w-5 text-indigo-400"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="mx-auto h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" py-10 pb-20">
          <div className="mx-auto mt-16 bg-slate-900 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-4xl font-bold tracking-tight text-slate-100">
                Enterprise plan
              </h3>
              <p className="mt-6  text-lg leading-7 text-slate-100">
                We also build far more robust and sophisticated websites, apps,
                and stores. For that and fully inclusive service, please reach
                out and we can work out an individualized plan for you.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none font-semibold leading-6 text-indigo-600">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 leading-6 text-slate-100 sm:grid-cols-2 sm:gap-6"
              >
                Every thing in premium plan plus what your business is needed.
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 h-full py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <ul className="list-disc  text-start">
                    <li className="list-item text-start">
                      Full support package
                    </li>
                    <li className="   list-item">Customized tech stack</li>
                    <li className="list-item">Tech & design consultations</li>
                    <li className="list-item">Business & copy consultations</li>
                    <li className="list-item">& more…</li>
                  </ul>

                  <a
                    href="https://calendly.com/kalvispetersons/introductory-call"
                    target="_blank"
                    className="mt-10 sm:text-lg block w-full rounded-md bg-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Let’s have a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
